import "./App.css";
import React, { useEffect, useState } from "react";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import {
  WalletConnectWallet,
  WalletConnectChainID,
} from "@tronweb3/walletconnect-tron";
const wallet = new WalletConnectWallet({
  network: WalletConnectChainID.Mainnet,
  options: {
    relayUrl: "wss://relay.walletconnect.com",
    projectId: "8bf18d6ab9d448f37b9dafd1ec21b5ed",
    metadata: {
      name: "JustLend",
      description: "JustLend WalletConnect",
      url: "https://app.justlend.org/",
      icons: ["https://app.justlend.org/mainLogo.svg"],
    },
  },
  web3ModalConfig: {
    themeMode: "dark",
    themeVariables: {
      "--w3m-z-index": 1000,
    },
    /**
     * Recommended Wallets are fetched from WalletConnect explore api:
     * https://walletconnect.com/explorer?type=wallet&version=2.
     * You can copy these ids from the page.
     */
    explorerRecommendedWalletIds: [
      "1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369",
      "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
    ],
  },
});
const { address } = await wallet.connect();
const App = ({ isOpened, onClose }) => {
  if (address) {
    return (
      <div>
        <h1>Connected</h1>
      </div>
    );
  } else {
    return (
      <div>
        <h1>Not Connected</h1>
      </div>
    );
  }
};

export default App;
